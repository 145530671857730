// import { GetStaticProps } from 'next'
// import { SWRConfig } from 'swr'
// import { getLaunchpadsApi } from 'state/launchpad/helpers'
// import { getFeaturedCollectionsApi } from 'state/nftMarket/helpers'
// import { getSettingsApi } from 'state/settings/helpers'
// import { getBannersApi } from 'state/banner/helpers'
// import Dashboard from './dashboard'

// const IndexPage = ({ settings, banners, launchpads, featuredCollections }) => {
//   return (
//     <SWRConfig
//       value={{
//         fallback: {
//           settings,
//           banners,
//           launchpads,
//           featuredCollections,
//         },
//       }}
//     >
//       <Dashboard />
//     </SWRConfig>
//   )
// }

// export const getStaticProps: GetStaticProps = async () => {
//   const results = {
//     launchpads: [],
//     featuredCollections: [],
//     settings: {},
//     banners: [],
//   }

//   try {
//     const settings = await getSettingsApi()
//     results.settings = settings
//   } catch (error) {
//     if (process.env.NODE_ENV === 'production') {
//       console.error('Error when fetching setting', error)
//     }
//   }

//   try {
//     const res = await getBannersApi()
//     results.banners = res.banners
//   } catch (error) {
//     if (process.env.NODE_ENV === 'production') {
//       console.error('Error when fetching banners', error)
//     }
//   }

//   try {
//     const res = await getLaunchpadsApi()
//     results.launchpads = res.launchpads
//   } catch (error) {
//     if (process.env.NODE_ENV === 'production') {
//       console.error('Error when fetching launchpad', error)
//     }
//   }

//   try {
//     const res = await getFeaturedCollectionsApi()
//     results.featuredCollections = res.data
//   } catch (error) {
//     if (process.env.NODE_ENV === 'production') {
//       console.error('Error when fetching featured collections', error)
//     }
//   }

//   return {
//     props: results,
//   }
// }

// IndexPage.chains = []

// export default IndexPage

import Dashboard from 'views/Dashboard'

const IndexPage = () => {
  return <Dashboard />
}

export default IndexPage
